/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

import OpenStatusMessageFactory from './hours/open-status/messagefactory.js';
import HoursTransformer from './hours/transformer.js';
import HoursStringsLocalizer from './hours/stringslocalizer.js';

export function _getLocaleWithDashes(locale) {
  return locale && locale.replace(/_/g, '-');
}

export function _getDocumentLocale() {
  return _getLocaleWithDashes(document.documentElement.lang);
}

export function openStatusDriveThru(profile, key = 'driveThroughHours', isTwentyFourHourClock, locale) {
  if (!profile[key]) {
    return '';
  }

  const hours = HoursTransformer.transform(profile[key], profile.timeZoneUtcOffset);
  if (!hours) {
    return '';
  }

  const hoursLocalizer = new HoursStringsLocalizer(
    _getLocaleWithDashes(locale) || _getDocumentLocale(), isTwentyFourHourClock);
  return new OpenStatusMessageFactory(hoursLocalizer)
    .create(hours.openStatus);
}